
import React, { useEffect, useState } from 'react';
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID, breadCrumbs$ } from "../../shared/app-service";
import { toast } from 'react-toastify';
import { apiPrefix } from '../../shared/api';
                
/*
* HELPER FUNCTIONS GO HERE:
* AND SHOULD BE EXPORTED FOR TESTING PURPOSES
* 
* HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
* END WITH HELPER
* 
* i.e -> testHelper
*/
                

export const usePiercingHook = () => {
  const [pageData, setPageData] = useState({});
  const [itemSelected, setItemSelected] = useState("book");
  const [artist, setArtist] = useState("");

  useEffect(() => {
    hasCookieAndStorage("piercing")
      ? setPageData(decode(appStore.get(`${appID}piercing`)))
      : setSessionData(appID, "piercing", `${apiPrefix}/piercing`, setPageData);
  }, []);


  
  // other component logic or functions
  
  return {
    // This return object is what gets passed as props to the componen
    pageData,
    itemSelected,
    setItemSelected,
    artist, 
    setArtist
  };
};              
                