import { toast } from 'react-toastify';
import {BehaviorSubject} from 'rxjs'

let shouldanimate = false;

const elementInView = (el, percentageScroll = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) /
      percentageScroll
  );
};

const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
};



export const handleScrollAnimation = (currentEl) => {
  if (currentEl) {
    if (elementInView(currentEl, 1.25)) {
      shouldanimate = true;
    }

    if (elementOutofView(currentEl)) {
      shouldanimate = false;
    }
  }

  return shouldanimate;
};

export const handleRoute = (r, w, h) => {
  const route = {
    locally: () => (h ? h(r) : ""),
    external: () => window.open(r, "_blank"),
  };
  return route[w]();
};

export const errorHandler = (e) => {
  toast.error('An error occurred, please try again')
  console.error('Error Handler: - ', e?.response?.data);
}

export const appID = "_bdt_";
export const showCart$ = new BehaviorSubject()
export const showMenu$ = new BehaviorSubject()
export const breadCrumbs$ = new BehaviorSubject([
  {
    name: '',
    link: 'home',
    hasCrumb: false
  },
])
export const selectedSize$ = new BehaviorSubject('')
export const handleCart$ = new BehaviorSubject('')
export const addCart$ = new BehaviorSubject('')
export const playVideo$ = new BehaviorSubject('')

