/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import artistSmiling from "../../assets/imgs/group-photo.png";
import artistAbout from "../../assets/imgs/krissy-q.png";
import artistPiercing from "../../assets/imgs/gabby-piercing.png";
import laserRemoval from "../../assets/imgs/laser-removal.png";
import tattooShirt from "../../assets/imgs/tshirts.png";
import { useNavigate } from "react-router-dom";
import { handleRoute } from "@cmj526/made-for-us";
import "./Styles.scss";
import NavigationComponent from "../../components/navigation";
import { pageObj } from "./Hooks";
import FooterComponent from "../../components/footer";
import { breadCrumbs$ } from "../../shared/app-service";

const MainComponent = ({ pageData: { main } }) => {
  const navigate = useNavigate();
  let crumbs = [
    {
      name: "Home",
      link: "/main",
      hasCrumb: true,
    },
    {
      name: "Artist",
      link: "/artist",
      hasCrumb: false,
    },
  ]
  return (
    <>
      <div className="main">
        {/* <div className="overlay-main"></div> */}
        <NavigationComponent />
        <main>
          <div id="slider">
            <div class="slider-inner">
              <div id="slider-content">
                <div class="meta">View</div>
                <h2 id="slide-title">
                  Artist <br />
                  Page
                </h2>
                <span data-slide-title="0">
                  Artist <br />
                  Page
                </span>
                <span data-slide-title="1">
                  About <br />
                  Page
                </span>
                <span data-slide-title="2">
                  Piercing <br />
                  Page
                </span>
                <span data-slide-title="3">
                  Laser <br />
                  Removal
                </span>
                <span data-slide-title="4">
                  Shop <br />
                  Page
                </span>
                <div class="meta">See</div>
                <button
                  onClick={() => {
                    breadCrumbs$.next(crumbs);
                    handleRoute(pageObj.navigateTo, "locally", navigate);
                  }}
                 
                  id="slide-status"
                >
                OUR TATTOO ARTIST
                </button>
                <span className="page-select" data-slide-status="0">
                  Our Tattoo Artist
                </span>
                <span className="page-select" data-slide-status="1">
                  Our Journey
                </span>
                <span className="page-select" data-slide-status="2">
                  Our Piercings
                </span>
                <span className="page-select" data-slide-status="3">
                  Our Laser Removal Process
                </span>
                <span className="page-select" data-slide-status="4">
                  Our Products
                </span>
              </div>
            </div>

            <img src={artistSmiling} />
            <img src={artistAbout} />
            <img src={artistPiercing} />
            <img src={laserRemoval} />
            <img src={tattooShirt} />

            <div id="pagination">
              <button
                class="active"
                data-slide="0"
                onClick={() => {
                  pageObj.navigateTo = "/artist"
                  crumbs = [
                    {
                      name: "Home",
                      link: "/main",
                      hasCrumb: true,
                    },
                    {
                      name: "Artist",
                      link: "/artist",
                      hasCrumb: false,
                    },
                  ]
                }}
              ></button>
              <button
                data-slide="1"
                onClick={() => {
                  pageObj.navigateTo = "/about"
                  crumbs = [
                    {
                      name: "Home",
                      link: "/main",
                      hasCrumb: true,
                    },
                    {
                      name: "About",
                      link: "/about",
                      hasCrumb: false,
                    },
                  ]
                }}
              ></button>
              <button
                data-slide="2"
                onClick={() => {
                  pageObj.navigateTo = "/piercing"
                  crumbs = [
                    {
                      name: "Home",
                      link: "/main",
                      hasCrumb: true,
                    },
                    {
                      name: "Piercing",
                      link: "/piercing",
                      hasCrumb: false,
                    },
                  ]
                }}
              ></button>
              <button
                data-slide="3"
                onClick={() => {
                  pageObj.navigateTo = "/laser-removal"
                  crumbs = [
                    {
                      name: "Home",
                      link: "/main",
                      hasCrumb: true,
                    },
                    {
                      name: "Laser Removal",
                      link: "/laser-removal",
                      hasCrumb: false,
                    },
                  ]
                }}
              ></button>
              <button
                data-slide="4"
                onClick={() => {
                  pageObj.navigateTo = "/store"
                  crumbs = [
                    {
                      name: "Home",
                      link: "/main",
                      hasCrumb: true,
                    },
                    {
                      name: "Store",
                      link: "/store",
                      hasCrumb: false,
                    },
                  ]
                }}
              ></button>
            </div>
          </div>
        </main>
      </div>
      <FooterComponent />
    </>
  );
};

export default MainComponent;
