
import React, { useEffect, useRef, useState } from 'react';
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";
import { apiPrefix } from '../../shared/api';
                
/*
* HELPER FUNCTIONS GO HERE:
* AND SHOULD BE EXPORTED FOR TESTING PURPOSES
* 
* HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
* END WITH HELPER
* 
* i.e -> testHelper
*/
                

export const useLaserRemovalHook = () => {
  const [pageData, setPageData] = useState({});
  const [itemSelected, setItemSelected] = useState("process");
  const videoEl = useRef(null);

  const attemptPlay = () => {
    if (videoEl.current) {
      videoEl?.current?.classList.add("video_animation");
        // videoEl?.current?.play().catch((error) => {
        //   console.error("Error attempting to play", error);
        // });
    }
  };

  useEffect(() => {
    hasCookieAndStorage("laser")
      ? setPageData(decode(appStore.get(`${appID}laser`)))
      : setSessionData(appID, "laser", `${apiPrefix}/laser`, setPageData);
  }, []);
  
  useEffect(() => {
    attemptPlay();
  }, [videoEl]);
  
  return { // This return object is what gets passed as props to the component
    pageData,
    itemSelected, 
    setItemSelected,
    videoEl
  }
};              
                