import React, { useEffect, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";
import { apiPrefix } from "../../shared/api";
/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

export const useAboutHook = () => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    hasCookieAndStorage("about")
      ? setPageData(decode(appStore.get(`${appID}about`)))
      : setSessionData(appID, "about", `${apiPrefix}/about`, setPageData);
  }, []);

  // other component logic or functions

  return {
    pageData,
    setPageData,
  };
};
