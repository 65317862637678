import React from "react";
import NavigationComponent from "../../components/navigation";
import styled, { css } from "styled-components";
import { breadCrumbs$ } from "../../shared/app-service";
import "./Styles.scss";
import FooterComponent from "../../components/footer";
import { artistPageInfo$ } from "../../components/booking-form";
import { handleRoute } from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import * as mobile from "is-mobile";

const Shirts = styled.div`
  cursor: pointer;
  position: relative;
  // top: 20%;
  padding: 0.2em 0.3em;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(136, 136, 138, 0.5);
    &_title_top {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 2px solid #ffc917;
      &_title_top {
        color: black;
      }
    `}
`;

const ShirtsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const ShirtsTitle = styled.span`
  font-family: "Varela Round";
  font-size: 1.2em !important;
  height: 0.3em;
  padding: 0.6em 0;
  color: white;
`;

const ShirtsSubtitle = styled.span`
  font-size: 0.7em !important;
  font-family: "Varela Round";
  font-size: 1em;
  color: #ffc917;
`;

const LaserRemovalComponent = ({
  pageData: { laser },
  itemSelected,
  setItemSelected,
  videoEl,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="laser">
        <NavigationComponent />
        <div className="overlay-laser-menu">
          <div className="overlay-laser-menu_title">
            {mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="l-mobile">
                  <li>L</li>
                  <li>A</li>
                  <li>S</li>
                  <li>E</li>
                  <li>R</li>
                </ul>
              </>
            )}

            {!mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="l">
                  <li>L</li>
                  <li>A</li>
                  <li>S</li>
                  <li>E</li>
                  <li>R</li>
                </ul>

                <ul id="r">
                  <li>R</li>
                  <li>E</li>
                  <li>M</li>
                  <li>O</li>
                  <li>V</li>
                  <li>A</li>
                  <li>L</li>
                </ul>
              </>
            )}
          </div>
          <div className="overlay-laser-menu_subtitle">
            Check out the amazing and enchanting products offered at our
            incredible tattoo shop! You won't believe the magic we can create
            for you!
          </div>
          {/*---------- Process ---------------*/}
          {laser?.openForBusiness?.process?.isOpen && (
            <Shirts
              onClick={() => {
                setItemSelected("process");
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Book an Appointment",
                    link: "/laser",
                    selected: "book laser",
                    hasCrumb: false,
                  },
                ]);
              }}
              itemSelected={itemSelected === "process"}
            >
              <ShirtsContent>
                <ShirtsTitle>How Laser Removal Works</ShirtsTitle>
                <ShirtsSubtitle>
                  Learn about the process of laser tattoo removal!
                </ShirtsSubtitle>
              </ShirtsContent>
            </Shirts>
          )}
          {/*---------- Book ---------------*/}
          {laser?.openForBusiness?.booking?.isOpen && (
            <Shirts
              onClick={() => {
                setItemSelected("book");
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Book an Appointment",
                    link: "/laser",
                    selected: "book laser",
                    hasCrumb: false,
                  },
                ]);
              }}
              itemSelected={itemSelected === "book"}
            >
              <ShirtsContent>
                <ShirtsTitle>Book an Appointment</ShirtsTitle>
                <ShirtsSubtitle>
                  Book an appointment with one of our amazing artists!
                </ShirtsSubtitle>
              </ShirtsContent>
            </Shirts>
          )}
        </div>
        {/*---------- Laser Process ---------------*/}
        {itemSelected === "process" && (
          <>
            <div className="laser_process">
              <h1>{laser?.howItWorks?.process?.title}</h1>
              <div className="laser_process_content">
                {laser?.howItWorks?.process?.content}
              </div>
              <h1>{laser?.howItWorks?.steps?.title}</h1>
              <div className="laser_process_steps">
                <ol>{laser?.howItWorks?.steps?.stepOne}</ol>
                <ol>{laser?.howItWorks?.steps?.stepTwo}</ol>
                <ol>{laser?.howItWorks?.steps?.stepThree}</ol>
                <ol>{laser?.howItWorks?.steps?.stepFour}</ol>
                <ol>{laser?.howItWorks?.steps?.stepFive}</ol>
                <p>{laser?.howItWorks?.steps?.final}</p>
              </div>
              <h1>{laser?.howItWorks?.technology?.title}</h1>
              <div className="laser_process_technology">
                {laser?.howItWorks?.technology?.content}
              </div>
              <h1>{laser?.howItWorks?.faq?.title}</h1>
              <div className="laser_process_faq">
                {laser?.howItWorks?.faq?.content?.top}
                <ol>
                  {laser?.howItWorks?.faq?.content?.question?.one?.q}
                  <ul>{laser?.howItWorks?.faq?.content?.question?.one?.a}</ul>
                </ol>
                <ol>
                  {laser?.howItWorks?.faq?.content?.question?.two?.q}
                  <ul>{laser?.howItWorks?.faq?.content?.question?.two?.a}</ul>
                </ol>
                <ol>
                  {laser?.howItWorks?.faq?.content?.question?.three?.q}
                  <ul>{laser?.howItWorks?.faq?.content?.question?.three?.a}</ul>
                </ol>
              </div>
            </div>
          </>
        )}

        {/*---------- Book ---------------*/}
        {itemSelected === "book" && (
          <div className="laser_artist">
            <div className="laser_artist_choose">
              <article class="card card--1">
                <div class="card__info-hover">
                  <svg class="card__like" viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                    />
                  </svg>
                  <div class="card__clock-info">
                    <svg class="card__clock" viewBox="0 0 24 24">
                      <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                    </svg>
                    <span class="card__time">30 min</span>
                  </div>
                </div>
                <div class="card__img"></div>
                <a href="#" class="card_link">
                  <div class="card__img--hover"></div>
                </a>
                <div class="card__info">
                  <span class="card__category">Certified Laser Specialist</span>
                  <h3 class="card__title">Quinten Harper</h3>
                  <span class="card__by">
                    by{" "}
                    <a href="#" class="card__author" title="author">
                      Astanza Laser
                    </a>
                  </span>
                  <button
                    onClick={() => {
                      artistPageInfo$.next({
                        artist: "Quinten Harper",
                        artistImg:
                          "https://bdt-site.s3.us-east-2.amazonaws.com/q-artist.png",
                        title: "Book Laser Removal Appointment",
                        type: "New Laser Removal Request",
                        previous: "/laser-removal",
                      });
                      handleRoute("/booking", "locally", navigate);
                    }}
                    class="card__button"
                  >
                    Book Artist
                  </button>
                </div>
              </article>

              <article class="card card--2">
                <div class="card__info-hover">
                  <svg class="card__like" viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                    />
                  </svg>
                  <div class="card__clock-info">
                    <svg class="card__clock" viewBox="0 0 24 24">
                      <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                    </svg>
                    <span class="card__time">30 min</span>
                  </div>
                </div>
                <div class="card__img"></div>
                <a href="#" class="card_link">
                  <div class="card__img--hover"></div>
                </a>
                <div class="card__info">
                  <span class="card__category">
                    Non-Ablative Tattoo specialist
                  </span>
                  <h3 class="card__title">Krissy</h3>
                  <span class="card__by">
                    by{" "}
                    <a href="#" class="card__author" title="author">
                      Astanza Laser
                    </a>
                  </span>
                  <button
                    onClick={() => {
                      artistPageInfo$.next({
                        artist: "Krissy The Butcher",
                        artistImg:
                          "https://bdt-site.s3.us-east-2.amazonaws.com/krissy-artist.png",
                        title: "Book Laser Removal Appointment",
                        type: "New Laser Removal Request",
                        previous: "/laser-removal",
                      });
                      handleRoute("/booking", "locally", navigate);
                    }}
                    class="card__button"
                  >
                    Book Artist
                  </button>
                </div>
              </article>

              <article class="card card--3">
                <div class="card__info-hover">
                  <svg class="card__like" viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                    />
                  </svg>
                  <div class="card__clock-info">
                    <svg class="card__clock" viewBox="0 0 24 24">
                      <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                    </svg>
                    <span class="card__time">30 min</span>
                  </div>
                </div>
                <div class="card__img"></div>
                <a href="#" class="card_link">
                  <div class="card__img--hover"></div>
                </a>
                <div class="card__info">
                  <span class="card__category">Certified Laser Specialist</span>
                  <h3 class="card__title">Miriam</h3>
                  <span class="card__by">
                    by{" "}
                    <a href="#" class="card__author" title="author">
                      Astanza Laser
                    </a>
                  </span>
                  <button
                    onClick={() => {
                      artistPageInfo$.next({
                        artist: "Miriam",
                        artistImg:
                          "https://bdt-site.s3.us-east-2.amazonaws.com/miriam.png",
                        title: "Book Laser Removal Appointment",
                        type: "New Laser Removal Request",
                        previous: "/laser-removal",
                      });
                      handleRoute("/booking", "locally", navigate);
                    }}
                    class="card__button"
                  >
                    Book Artist
                  </button>
                </div>
              </article>
            </div>
          </div>
        )}
      </div>
      {/*---------- Laser Process Bottom ---------------*/}
      {itemSelected === "process" && (
        <>
          <h1 className="laser_title">Customer Pictures</h1>
          <div className="laser_artist">
            <article class="card card--laser1">
              <div class="card__info-hover">
                <svg class="card__like" viewBox="0 0 24 24">
                  <path
                    fill="#000000"
                    d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                  />
                </svg>
                <div class="card__clock-info">
                  <svg class="card__clock" viewBox="0 0 24 24">
                    <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">30 min</span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <span class="card__category">Forearm</span>
                <h3 class="card__title">Portrait Removal</h3>
                <span class="card__by">
                  by{" "}
                  <a href="#" class="card__author" title="author">
                    Bloodline Dynasty
                  </a>
                </span>
              </div>
            </article>

            <article class="card card--laser2">
              <div class="card__info-hover">
                <svg class="card__like" viewBox="0 0 24 24">
                  <path
                    fill="#000000"
                    d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                  />
                </svg>
                <div class="card__clock-info">
                  <svg class="card__clock" viewBox="0 0 24 24">
                    <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">30 min</span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <span class="card__category">Right Arm</span>
                <h3 class="card__title">Picture Removal</h3>
                <span class="card__by">
                  by{" "}
                  <a href="#" class="card__author" title="author">
                    Bloodline Dynasty
                  </a>
                </span>
              </div>
            </article>

            <article class="card card--laser3">
              <div class="card__info-hover">
                <svg class="card__like" viewBox="0 0 24 24">
                  <path
                    fill="#000000"
                    d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
                  />
                </svg>
                <div class="card__clock-info">
                  <svg class="card__clock" viewBox="0 0 24 24">
                    <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">30 min</span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <span class="card__category">Front Chest</span>
                <h3 class="card__title">Shade Removal</h3>
                <span class="card__by">
                  by{" "}
                  <a href="#" class="card__author" title="author">
                    Bloodline Dynasty
                  </a>
                </span>
              </div>
            </article>
          </div>
          <h1 className="laser_title">
            {laser?.howItWorks?.experience?.title}
          </h1>
          <div className="laser_videos">
            {laser?.howItWorks?.experience?.videos.map((vid, idx) => (
              <video
                id={`laser-vid-${idx}`}
                playsInline
                loop
                controls
                autoPlay={false}
                alt="All the devices"
                src={vid}
              ></video>
            ))}
          </div>

          <h1 className="laser_title">{laser?.howItWorks?.testimony?.title}</h1>
          <div className="laser_testimony">
            {laser?.howItWorks?.testimony?.videos.map((vid, idx) => (
              <video
                id={`laser-vid-${idx}`}
                playsInline
                loop
                controls
                autoPlay={false}
                alt="All the devices"
                src={vid}
              ></video>
            ))}
          </div>
        </>
      )}
      <FooterComponent />
    </>
  );
};

export default LaserRemovalComponent;
