import React, { useEffect, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";
import { gsap } from "gsap";
import { apiPrefix } from "../../shared/api";

/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

export const pageObj = {
  navigateTo: "/artist",
};

export const useMainHook = () => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    console.log("pageData", pageData);
    if (pageData?.main) {
      const displacementSlider = function (opts) {
        let vertex = `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `;
  
        let fragment = `
            
            varying vec2 vUv;
    
            uniform sampler2D currentImage;
            uniform sampler2D nextImage;
    
            uniform float dispFactor;
    
            void main() {
    
                vec2 uv = vUv;
                vec4 _currentImage;
                vec4 _nextImage;
                float intensity = 0.3;
    
                vec4 orig1 = texture2D(currentImage, uv);
                vec4 orig2 = texture2D(nextImage, uv);
                
                _currentImage = texture2D(currentImage, vec2(uv.x, uv.y + dispFactor * (orig2 * intensity)));
    
                _nextImage = texture2D(nextImage, vec2(uv.x, uv.y + (1.0 - dispFactor) * (orig1 * intensity)));
    
                vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);
    
                gl_FragColor = finalTexture;
    
            }
        `;
  
        let images = opts.images,
          image,
          sliderImages = [];
        let canvasWidth = images[0].clientWidth;
        let canvasHeight = images[0].clientHeight;
        let parent = opts.parent;
        let renderWidth = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );
        let renderHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
  
        let renderW, renderH;
  
        if (renderWidth > canvasWidth) {
          renderW = renderWidth;
        } else {
          renderW = canvasWidth;
        }
  
        renderH = canvasHeight;
  
        let renderer = new window.THREE.WebGLRenderer({
          antialias: false,
        });
  
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0x23272a, 1.0);
        renderer.setSize(renderW, renderH);
        parent.appendChild(renderer.domElement);
  
        let loader = new window.THREE.TextureLoader();
        loader.crossOrigin = "anonymous";
  
        images.forEach((img) => {
          image = loader.load(img.getAttribute("src") + "?v=" + Date.now());
          image.magFilter = image.minFilter = window.THREE.LinearFilter;
          image.anisotropy = renderer.capabilities.getMaxAnisotropy();
          sliderImages.push(image);
        });
  
        let scene = new window.THREE.Scene();
        scene.background = new window.THREE.Color(0x050505);
        let camera = new window.THREE.OrthographicCamera(
          renderWidth / -2,
          renderWidth / 2,
          renderHeight / 2,
          renderHeight / -2,
          1,
          1000
        );
  
        camera.position.z = 1;
  
        let mat = new window.THREE.ShaderMaterial({
          uniforms: {
            dispFactor: { type: "f", value: 0.0 },
            currentImage: { type: "t", value: sliderImages[0] },
            nextImage: { type: "t", value: sliderImages[1] },
          },
  
          vertexShader: vertex,
          fragmentShader: fragment,
          transparent: true,
          opacity: 1.0,
        });
  
        let geometry = new window.THREE.PlaneGeometry(
          parent.offsetWidth,
          parent.offsetHeight,
          1
        );
  
        let object = new window.THREE.Mesh(geometry, mat);
        object.position.set(0, 0, 0);
        scene.add(object);
  
        let addEvents = function () {
          let pagButtons = Array.from(
            document.getElementById("pagination").querySelectorAll("button")
          );
          let isAnimating = false;
  
          pagButtons.forEach((el) => {
            el.addEventListener("click", function () {
              if (!isAnimating) {
                isAnimating = true;
  
                document
                  .getElementById("pagination")
                  .querySelectorAll(".active")[0].className = "";
                this.className = "active";
  
                let slideId = parseInt(this.dataset.slide, 10);
  
                mat.uniforms.nextImage.value = sliderImages[slideId];
                mat.uniforms.nextImage.needsUpdate = true;
  
                gsap.to(mat.uniforms.dispFactor, 1, {
                  value: 1,
                  ease: "Expo.easeInOut",
                  onComplete: function () {
                    mat.uniforms.currentImage.value = sliderImages[slideId];
                    mat.uniforms.currentImage.needsUpdate = true;
                    mat.uniforms.dispFactor.value = 0.0;
                    isAnimating = false;
                  },
                });
  
                let slideTitleEl = document.getElementById("slide-title");
                let slideStatusEl = document.getElementById("slide-status");
                let nextSlideTitle = document.querySelectorAll(
                  `[data-slide-title="${slideId}"]`
                )[0].innerHTML;
                let nextSlideStatus = document.querySelectorAll(
                  `[data-slide-status="${slideId}"]`
                )[0].innerHTML;
  
                gsap.fromTo(
                  slideTitleEl,
                  0.5,
                  {
                    autoAlpha: 1,
                    y: 0,
                  },
  
                  {
                    autoAlpha: 0,
                    y: 20,
                    ease: "Expo.easeIn",
                    onComplete: function () {
                      slideTitleEl.innerHTML = nextSlideTitle;
  
                      gsap.to(slideTitleEl, 0.5, {
                        autoAlpha: 1,
                        y: 0,
                      });
                    },
                  }
                );
  
                gsap.fromTo(
                  slideStatusEl,
                  0.5,
                  {
                    autoAlpha: 1,
                    y: 0,
                  },
  
                  {
                    autoAlpha: 0,
                    y: 20,
                    ease: "Expo.easeIn",
                    onComplete: function () {
                      slideStatusEl.innerHTML = nextSlideStatus;
  
                      gsap.to(slideStatusEl, 0.5, {
                        autoAlpha: 1,
                        y: 0,
                        delay: 0.1,
                      });
                    },
                  }
                );
              }
            });
          });
        };
  
        addEvents();
  
        document.addEventListener("resize", function (e) {
          renderer.setSize(renderW, renderH);
        });
  
        let animate = function () {
          requestAnimationFrame(animate);
  
          renderer.render(scene, camera);
        };
        animate();
      };
  
      // eslint-disable-next-line no-undef
      imagesLoaded(document.querySelectorAll("img"), () => {
        document.body.classList.remove("loading");
  
        const el = document.getElementById("slider");
        const imgs = Array.from(el.querySelectorAll("img"));
        new displacementSlider({
          parent: el,
          images: imgs,
        });
      });
    } 
    console.log("window obj", window);
  }, [pageData, setPageData]);


  useEffect(() => {
    pageObj.navigateTo = "/artist"
    hasCookieAndStorage("main")
      ? setPageData(decode(appStore.get(`${appID}main`)))
      : setSessionData(appID, "main", `${apiPrefix}/main`, setPageData);
  }, []);

  // other component logic or functions

  return {
    pageData,
    setPageData,
  };
};
