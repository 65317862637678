import React from "react";
import "./Styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import NavigationComponent from "../../components/navigation";
import FooterComponent from "../../components/footer";
import { breadCrumbs$ } from "../../shared/app-service";

const AboutComponent = ({ pageData: { about } }) => {
  console.log("about", about);
  breadCrumbs$.next([
    {
      name: "Home",
      link: "/main",
      hasCrumb: true,
    },
    {
      name: "About",
      selected: "about",
      link: "/about",
      hasCrumb: false,
    },
  ]);
  return (
    <>
      <NavigationComponent />
      <div className="about">
        <div className="journey">
          <div className="intro">
            <h3>Bloodline Dynasty Tattoos</h3>
            <h2>Our Journey</h2>
          </div>
          <div className="beginning">
            <div className="beginning_content">
              <h3>"{about?.journey?.content?.title}"</h3>
              <h2>{about?.journey?.content?.top}</h2>
            </div>
          </div>
          <div className="story">
            <h3>"The Blessing"</h3>
            <h2>{about?.journey?.content?.middle}</h2>
          </div>
          <div className="now">
            <h3>"The NOW!!"</h3>
            <h2>{about?.journey?.content?.bottom}</h2>
          </div>
        </div>
      </div>
      <div class="family">
        <div class="photos">
          <section class="photos_items">
            <figure class="photos_item">
              <img src="https://bdt-site.s3.us-east-2.amazonaws.com/q-and-k.png" />

            </figure>

            <figure class="photos_item">
              <img src="https://bdt-site.s3.us-east-2.amazonaws.com/q-and-mother.png" />
  
            </figure>

            <figure class="photos_item">
              <img src="https://bdt-site.s3.us-east-2.amazonaws.com/q-and-honey.png" />
      
            </figure>

            <figure class="photos_item">
              <img src="https://bdt-site.s3.us-east-2.amazonaws.com/q-and-k.png" />
      
            </figure>
          </section>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default AboutComponent;
