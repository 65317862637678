// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.artist-work {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 2em;
}`, "",{"version":3,"sources":["webpack://./src/components/artist-work/styles.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,QAAA;AAAJ","sourcesContent":["\n.artist-work {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    gap: 2em;\n}             \n                "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
