/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleRoute } from "@cmj526/made-for-us";
import "./styles.scss";
import {
  breadCrumbs$,
  handleCart$,
  showCart$,
  appID,
  addCart$,
} from "../../shared/app-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartCirclePlus,
  faCartCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import * as mobile from "is-mobile";

const NavigationComponent = () => {
  const items = JSON.parse(localStorage.getItem(`${appID}cart`));
  const navigate = useNavigate();
  let isCartShowing = false;
  const [crumbs, setCrumbs] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem(`${appID}cart`))?.length || 0
  );

  const routes = [
    {
      name: "Home",
      path: "/main",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Artist",
      path: "/artist",
    },
    {
      name: "Piercing",
      path: "/piercing",
    },
    {
      name: "Laser-Removal",
      path: "/laser-removal",
    },
    {
      name: "Store",
      path: "/store",
    },
  ];

  useEffect(() => {
    breadCrumbs$.subscribe((crumb) => {
      crumb.map((c) => {
        if (c.name === "Store") {
          setShowCart(true);
        }
      });
      setCrumbs(crumb);
    });

    handleCart$.subscribe((cart) => {
      setCart(cart);
    });

    showCart$.subscribe((cart) => {
      setIsCartOpen(cart);
    });

    addCart$.next(items);

    if (items?.length > 0) {
      setCart(items);
    }
  }, []);

  return (
    <header>
      {!mobile({
        featureDetect: true,
        tablet: true,
      }) && (
        <div class="inner navigation">
          <div className="navigation_left"></div>
          <div
            className="navigation_logo"
            onClick={() => {
              handleRoute("/main", "locally", navigate);

              breadCrumbs$.next([
                {
                  name: "",
                  link: "home",
                  hasCrumb: false,
                },
              ]);
            }}
            class="logo"
          >
            <img src="https://bdt-site.s3.us-east-2.amazonaws.com/bdt-logo-clear.png" />
          </div>

          {!mobile({
            featureDetect: true,
            tablet: true,
          }) && (
            <nav>
              {crumbs.length === 1 && (
                <div className="navigation_company">
                  <ul id="n">
                    <li>B</li>
                    <li>L</li>
                    <li>O</li>
                    <li>O</li>
                    <li>D</li>
                    <li>L</li>
                    <li>I</li>
                    <li>N</li>
                    <li>E</li>
                  </ul>
                  <ul id="a">
                    <li>D</li>
                    <li>Y</li>
                    <li>N</li>
                    <li>A</li>
                    <li>S</li>
                    <li>T</li>
                    <li>Y</li>
                  </ul>

                  <ul id="v">
                    <li>T</li>
                    <li>A</li>
                    <li>T</li>
                    <li>T</li>
                    <li>O</li>
                    <li>O</li>
                    <li>S</li>
                  </ul>
                </div>
              )}
              {crumbs?.map((crumb) => (
                <a href={crumb?.link !== "" ? crumb?.link : "#"}>
                  {crumb.name}

                  {crumb?.hasCrumb && (
                    <svg
                      width="24px"
                      height="24px"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      color="#ffc917"
                    >
                      <path
                        d="M4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14Z"
                        stroke="#ffc917"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M9 12H22M22 12L19 9M22 12L19 15"
                        stroke="#ffc917"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )}
                </a>
              ))}
            </nav>
          )}

          {!isCartOpen && showCart && (
            <div
              onClick={() => {
                isCartShowing = !isCartShowing;
                setIsCartOpen(isCartShowing);
                showCart$.next(isCartShowing);
              }}
              className="navigation_cart"
            >
              <Badge badgeContent={cart?.length} color="primary">
                <FontAwesomeIcon icon={faCartCirclePlus} />
              </Badge>
            </div>
          )}

          {isCartOpen && showCart && (
            <div
              onClick={() => {
                isCartShowing = !isCartShowing;
                setIsCartOpen(isCartShowing);
                showCart$.next(isCartShowing);
              }}
              className="navigation_cart"
            >
              <Tooltip title="Double click">
                <Badge badgeContent={cart?.length} color="primary">
                  <FontAwesomeIcon icon={faCartCircleXmark} />
                </Badge>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {mobile({
        featureDetect: true,
        tablet: true,
      }) && (
        <div className="mobileNav_container">
          <div class="background">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              class="menu__icon"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          {!isCartOpen && showCart && (
            <div
              onClick={() => {
                isCartShowing = !isCartShowing;
                setIsCartOpen(isCartShowing);
                showCart$.next(isCartShowing);
              }}
              className="navigation_cart"
            >
              <Badge badgeContent={cart?.length} color="primary">
                <FontAwesomeIcon icon={faCartCirclePlus} />
              </Badge>
            </div>
          )}

          {isCartOpen && showCart && (
            <div
              onClick={() => {
                isCartShowing = !isCartShowing;
                setIsCartOpen(isCartShowing);
                showCart$.next(isCartShowing);
              }}
              className="navigation_cart"
            >
              <Tooltip title="Double click">
                <Badge badgeContent={cart?.length} color="primary">
                  <FontAwesomeIcon icon={faCartCircleXmark} />
                </Badge>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {isMenuOpen && (
        <nav className="mobileNav">
          {routes.map((route) => (
            <a href={route.path}>
              {route.name}
              <svg
                width="24px"
                height="24px"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="#ffc917"
              >
                <path
                  d="M4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14Z"
                  stroke="#ffc917"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M9 12H22M22 12L19 9M22 12L19 15"
                  stroke="#ffc917"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </a>
          ))}
        </nav>
      )}
    </header>
  );
};

export default NavigationComponent;
