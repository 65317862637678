
import React, { useEffect, useState } from "react";
import useSound from 'use-sound';
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute,
} from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import { appID, handleScrollAnimation } from "../../shared/app-service";
import { apiPrefix } from "../../shared/api";

/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

export const useWelcomeHook = () => {
  const [pageData, setPageData] = useState({});
  console.log('welcome hook')

  // const [play] = useSound("https://www.youtube.com/watch?v=VINnS6FKM54");
  // useEffect(() => {
  //   play();
  // }, [play]);

  useEffect(() => {
    console.log('called')
    hasCookieAndStorage("welcome")
      ? setPageData(decode(appStore.get(`${appID}welcome`)))
      : setSessionData(appID, "welcome", `${apiPrefix}/welcome`, setPageData);
  }, []);

  return {
    pageData,
    setPageData,
  };
};
