import axios from "axios";

export const apiPrefix = "/api";

export const retrieve = async (u) => {
  return await axios.get(u);
};

export const send = async (u, d) => {
  return await axios.post(u, d);
};
