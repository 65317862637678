import React from "react";
import "./styles.scss";


const PillComponent = (props) => {

  return (
    <div className="pill">
      <label for="cars">Choose Size:</label>
      <select
        onChange={(e) => {
          props?.sizes.map((item, idx) => {
            if (item.size === e.target.value) {
              props?.handleSelect({...item, idx});
            }
          })
        }}
        name="sizes"
        id="sizes"
      >
        {props?.sizes.map((shirt, idx) => (
          <option id={idx} value={shirt?.size}>
            {shirt?.size}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PillComponent;
