
import React from 'react';
import LaserRemovalComponent from './Component';
import { useLaserRemovalHook } from './Hooks';



const LaserRemoval = () => {
  return <LaserRemovalComponent {...useLaserRemovalHook()} />
}
export default LaserRemoval;           
                