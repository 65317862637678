/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import "./Styles.scss";
import "./welcome";
import {
  handleRoute,
} from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";

const WelcomeComponent = ({ pageData: { welcome } }) => {
  console.log("welcome", welcome);
  const navigate = useNavigate();
  return (
    <div className="welcome">
      <ul id="content">
        <li>B</li>
        <li>L</li>
        <li>O</li>
        <li>O</li>
        <li>D</li>
        <li>L</li>
        <li>I</li>
        <li>N</li>
        <li>E</li>
      </ul>

      <ul id="content2">
        <li>D</li>
        <li>Y</li>
        <li>N</li>
        <li>A</li>
        <li>S</li>
        <li>T</li>
        <li>Y</li>
      </ul>

      <ul id="content3">
        <li>T</li>
        <li>A</li>
        <li>T</li>
        <li>T</li>
        <li>O</li>
        <li>O</li>
        <li>S</li>
      </ul>

      <div className="overlay"></div>
      <div class="gallery">
        <div class="gallery_line">
          {welcome &&
            welcome.imgGallery.top.map((item) => <img src={item} alt="" />)}
        </div>
        <div class="gallery_line">
          {welcome &&
            welcome.imgGallery.middle.map((item) => <img src={item} alt="" />)}
        </div>
        <div class="gallery_line">
          {welcome &&
            welcome.imgGallery.bottom.map((item) => <img src={item} alt="" />)}
        </div>
      </div>

      <button
        onClick={() => {
          handleRoute("/main", "locally", navigate);
        }}
        role="button"
        class="golden-button"
      >
        <span class="golden-text">Enter</span>
      </button>
    </div>
  );
};

export default WelcomeComponent;
