import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Welcome from "./pages/Welcome";
import Main from "./pages/Main";
import Artist from "./pages/Artist";
import About from "./pages/About";
import Piercing from "./pages/Piercing";
import LaserRemoval from "./pages/Laser-removal";
import Shop from "./pages/Shop";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookingFormComponent from "./components/booking-form";
import ArtistWorkComponent from "./components/artist-work";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/main",
    element: <Main />,
  },
  {
    path: "/artist",
    element: <Artist />,
  },
  {
    path: "/artist-work",
    element: <ArtistWorkComponent />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/booking",
    element: <BookingFormComponent hasImgUpload={true} />,
  },
  {
    path: "/piercing",
    element: <Piercing />,
  },
  {
    path: "/laser-removal",
    element: <LaserRemoval />,
  },
  {
    path: "/store",
    element: <Shop />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <RouterProvider router={router} />
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
