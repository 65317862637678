import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import "./styles.scss";
import { BehaviorSubject } from "rxjs";

const ArtistWork = styled.div`
  width: 20em;
  height: 40em;
  border-radius: 4px;
  border: 2px solid #88888a;
  box-shadow: 0 0 3em -1em #88888a;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const artistWorkInfo$ = new BehaviorSubject(null);

const ArtistWorkComponent = () => {
  const [artistWorkInfo, setArtistWorkInfo] = useState({});

  useEffect(() => {
    artistWorkInfo$.subscribe((artist) => {
      console.log("artist work Info", artist);
      if (artist !== null) {
        setArtistWorkInfo(artist);
      }
    });
  }, []);
  return (
    <div className="artist-work">
      {artistWorkInfo?.tattoos?.map((tattoo) => (
        <ArtistWork img={tattoo?.img} />
      ))}
    </div>
  );
};

export default ArtistWorkComponent;
