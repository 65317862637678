import React from "react";
import NavigationComponent from "../../components/navigation";
import styled, { css } from "styled-components";
import { breadCrumbs$ } from "../../shared/app-service";
import "./Styles.scss";
import FooterComponent from "../../components/footer";
import BookingFormComponent from "../../components/booking-form";
import * as mobile from "is-mobile";

const ShirtsTitle = styled.span`
  font-family: "Varela Round";
  font-size: 1.2em !important;
  height: 0.3em;
  padding: 0.6em 0;
  display: block;
  color: white;
`;

const PiercingArtist = styled.div`
  cursor: pointer;
  position: relative;
  // top: 20%;
  padding: 0.2em 0.3em;
  background-color: #050505;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(255, 255, 255, 1);
    &_title_top {
      color: black;
    }

    ${ShirtsTitle} {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 1px solid rgba(136, 136, 138, 1);
      background-color: rgba(255, 255, 255, 1);
      &_title_top {
        color: black;
      }

      ${ShirtsTitle} {
        color: black;
      }
    `}
`;

const PiercingJewelry = styled.div`
  cursor: pointer;
  position: relative;
  // top: 20%;
  padding: 0.2em 0.3em;
  background-color: #050505;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(255, 255, 255, 1);
    &_title_top {
      color: black;
    }

    ${ShirtsTitle} {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 1px solid rgba(136, 136, 138, 1);
      background-color: rgba(255, 255, 255, 1);
      &_title_top {
        color: black;
      }

      ${ShirtsTitle} {
        color: black;
      }
    `}
`;

const ShirtsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const ShirtsSubtitle = styled.span`
  font-size: 0.7em !important;
  font-family: "Varela Round";
  font-size: 1em;
  color: #88888a;
`;

const PiercingComponent = ({
  pageData: { piercing },
  itemSelected,
  setItemSelected,
  artist,
  setArtist,
}) => {
  return (
    <>
      <div className="piercing">
        <NavigationComponent />
        <div className="overlay-piercing-menu">
          <div className="overlay-piercing-menu_title">
            {mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="p-mobile">
                  <li>P</li>
                  <li>I</li>
                  <li>E</li>
                  <li>R</li>
                  <li>C</li>
                  <li>I</li>
                  <li>N</li>
                  <li>G</li>
                </ul>
              </>
            )}

            {!mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="p">
                  <li>P</li>
                  <li>I</li>
                  <li>E</li>
                  <li>R</li>
                  <li>C</li>
                  <li>I</li>
                  <li>N</li>
                  <li>G</li>
                </ul>

                <ul id="sh">
                  <li>S</li>
                  <li>H</li>
                  <li>O</li>
                  <li>P</li>
                </ul>
              </>
            )}
          </div>
          <div className="overlay-piercing-menu_subtitle">
            Check out the amazing and enchanting products offered at our
            incredible tattoo shop! You won't believe the magic we can create
            for you!
          </div>
          <PiercingJewelry
            onClick={() => {
              setItemSelected("book");
              breadCrumbs$.next([
                {
                  name: "Home",
                  link: "/main",
                  hasCrumb: true,
                },
                {
                  name: "Book An Appointment",
                  link: "/store",
                  selected: "shirts",
                  hasCrumb: false,
                },
              ]);
            }}
            itemSelected={itemSelected === "book"}
          >
            <ShirtsContent>
              <ShirtsTitle>Piercing & Scheduling Information</ShirtsTitle>
              <ShirtsSubtitle>
                Book an appointment with our artist
              </ShirtsSubtitle>
            </ShirtsContent>
          </PiercingJewelry>
        </div>
        {itemSelected === "book" && (
          <div className="piercing_container">
            <div className="piercing_instructions">
              <h2>Piercing Protocols</h2>
              <p>{piercing?.protocol?.content?.walkIn}</p>
              <p>{piercing?.protocol?.content?.appointment}</p>
              <p>{piercing?.protocol?.content?.photoId}</p>
              <p>{piercing?.protocol?.content?.parentalConsent}</p>
            </div>
            <div className="piercing_prices">
              <div className="piercing_prices_items">
                <h2>Piercing Price List</h2>
                <li>
                  Ear
                  {piercing?.priceList?.ear.map((e) => (
                    <ol>{e}</ol>
                  ))}
                </li>
                <li>
                  Nose
                  {piercing?.priceList?.nose.map((e) => (
                    <ol>{e}</ol>
                  ))}
                </li>
                <li>
                  Mouth
                  {piercing?.priceList?.mouth.map((e) => (
                    <ol>{e}</ol>
                  ))}
                </li>
                <li>
                  Private
                  {piercing?.priceList?.private.map((e) => (
                    <ol>{e}</ol>
                  ))}
                </li>
              </div>

              <div className="piercing_prices_booking">
                <BookingFormComponent
                  title="Schedule Piercing Appointment"
                  type="New Piercing Request"
                  artist="Bloodline Dynasty"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="piercing_artist">
        <article class="p p--1">
          <div class="p__info-hover">
            <svg class="p__like" viewBox="0 0 24 24">
              <path
                fill="#000000"
                d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
              />
            </svg>
          </div>
          <div class="p__img"></div>
          <a href="#" class="p_link">
            <div class="p__img--hover"></div>
          </a>
          <div class="p__info">
            <span class="p__category">Book An Appointment</span>
            <h3 class="p__title">Full Face Piercing</h3>
            <span class="p__by">
              by{" "}
              <a href="#" class="p__author" title="author">
                Bloodline Dynasty
              </a>
            </span>
          </div>
        </article>

        <article class="p p--4">
          <div class="p__info-hover">
            <svg class="p__like" viewBox="0 0 24 24">
              <path
                fill="#000000"
                d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
              />
            </svg>
          </div>
          <div class="p__img"></div>
          <a href="#" class="p_link">
            <div class="p__img--hover"></div>
          </a>
          <div class="p__info">
            <span class="p__category">Book An Appointment</span>
            <h3 class="p__title">Nose Piercing</h3>
            <span class="p__by">
              by{" "}
              <a href="#" class="p__author" title="author">
                Bloodline Dynasty
              </a>
            </span>
          </div>
        </article>

        <article class="p p--3">
          <div class="p__info-hover">
            <svg class="p__like" viewBox="0 0 24 24">
              <path
                fill="#000000"
                d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z"
              />
            </svg>
          </div>
          <div class="p__img"></div>
          <a href="#" class="p_link">
            <div class="p__img--hover"></div>
          </a>
          <div class="p__info">
            <span class="p__category">Book An Appointment</span>
            <h3 class="p__title">Nose and Lip Piercing</h3>
            <span class="p__by">
              by{" "}
              <a href="#" class="p__author" title="author">
                Bloodline Dynasty
              </a>
            </span>
          </div>
        </article>
      </div>
      <FooterComponent />
    </>
  );
};

export default PiercingComponent;
