import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCommentLines,
  faTruckFast,
  faMinus,
  faPlus,
  faTrashCanList,
} from "@fortawesome/pro-solid-svg-icons";
import { apiPrefix, send } from "../../shared/api";
import {
  addCart$,
  appID,
  errorHandler,
  handleCart$,
  showCart$,
} from "../../shared/app-service";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const CartComponent = (props) => {
  const items = JSON.parse(localStorage.getItem(`${appID}cart`));
  const [checkoutCart, setCheckoutCart] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const deleteItem = (idx) => {
    items.splice(idx, 1);
    localStorage.setItem(`${appID}cart`, JSON.stringify(items));
    addCart$.next(items);
    handleCart$.next(items);
    toast.info("Item Deleted");
  };

  const addTotalPrice = useCallback((cart) => {
    console.log("addTotal items", items);
    if (cart.length > 0) {
      let total = 0;
      cart.map((item) => {
        total += item.totalPrice;
      });
      setCartTotal(total);
    } else {
      setCartTotal(0);
    }
  }, []);

  const minusToQuantity = (idx) => {
    console.log("minus", idx);
    if (items[idx].quantity === 1) {
      toast.error("Please Delete Item");
    } else {
      items[idx].quantity -= 1;
      items[idx].item.price =
        JSON.parse(items[idx].item.price) - items[idx].item.originalPrice;
      items[idx].totalPrice = items[idx].item.price;
      localStorage.setItem(`${appID}cart`, JSON.stringify(items));
      handleCart$.next(items);
      addTotalPrice(items);
    }
  };

  const addToQuantity = (idx) => {
    if (items[idx].quantity >= 20) {
      toast.error("Max Quantity Reached");
      addTotalPrice();
    } else {
      items[idx].quantity += 1;
      items[idx].item.price =
        JSON.parse(items[idx].item.price) + items[idx].item.originalPrice;
      items[idx].totalPrice = items[idx].item.price;
      localStorage.setItem(`${appID}cart`, JSON.stringify(items));
      handleCart$.next(items);
      addTotalPrice(items);
    }
  };

  const clearCart = () => {
    addCart$.next([]);
    localStorage.setItem(`${appID}cart`, JSON.stringify([]));
    handleCart$.next([]);
    showCart$.next(false);
  };

  useEffect(() => {
    handleCart$.subscribe((cart) => {
      setCheckoutCart(cart);
    });

    addCart$.subscribe((cart) => {
      addTotalPrice(cart);
    });

    if (items?.length > 0) {
      setCheckoutCart(items);
    }
  }, []);

  return (
    <div className="cart">
      <div className="cart_header">
        <div className="cart_header_name">
          My Cart {checkoutCart?.length > 0 && `(${checkoutCart?.length})`}
        </div>

        <div className="cart_header_total">
          Subtotal: ${cartTotal.toFixed(2) || "0.00"}
        </div>
        <div onClick={() => clearCart()} className="cart_header_view">
          Clear
        </div>
      </div>
      <div className="cart_items">
        <div className="cart_items_title">
          <div className="cart_items_title_icon">
            <img
              src="https://bdt-site.s3.us-east-2.amazonaws.com/bdt-logo-clear.png"
              alt=""
            />
          </div>
          <div className="cart_items_title_container">
            <div className="cart_items_title_container_name">
              Bloodline Dynasty
            </div>
            <div className="cart_items_title_container_rating">
              <div className="cart_items_title_container_rating_icon">
                <FontAwesomeIcon
                  className="cart_items_title_container_rating_icon_i"
                  icon={faStar}
                />
                <span className="cart_items_title_container_rating_icon_num">
                  5.0
                </span>
              </div>
              <div className="cart_items_title_container_rating_icon">
                <FontAwesomeIcon
                  className="cart_items_title_container_rating_icon_ii"
                  icon={faCommentLines}
                />
                <span className="cart_items_title_container_rating_icon_num">
                  99%
                </span>
              </div>
            </div>
          </div>
        </div>
        {checkoutCart?.length > 0 &&
          checkoutCart.map((cart, idx) => (
            <div className="cart_items_item">
              <div className="cart_items_item_top">
                <div className="cart_items_item_top_image">
                  <img src={cart?.item?.img?.front} alt="" />
                </div>
                <div className="cart_items_item_top_name-container">
                  <div className="cart_items_item_top_name-container_category">
                    {cart?.item?.category.toUpperCase()}
                  </div>
                  <div className="cart_items_item_top_name-container_name">
                    {cart?.item?.name}
                  </div>
                  <div className="cart_items_item_top_name-container_label">
                    <div className="cart_items_item_top_name-container_label_left">
                      <div className="cart_items_item_top_name-container_label_left_name">
                        {cart?.item?.available && <>Size:</>}
                        {!cart?.item?.available && <>Type:</>}
                      </div>
                      <div className="cart_items_item_top_name-container_label_left_value">
                        {cart?.item?.available &&
                          cart?.item?.available.find(
                            (x) => x.apiId === cart?.id
                          ).size}

                        {!cart?.item?.available && cart?.item?.category}
                      </div>
                    </div>

                    <div className="cart_items_item_top_name-container_label_right">
                      <div className="cart_items_item_top_name-container_label_right_name">
                        Color:
                      </div>
                      <div className="cart_items_item_top_name-container_label_right_value">
                        {cart?.item?.name.split(" ")[0]}
                      </div>
                    </div>
                  </div>
                  <div className="cart_items_item_top_name-container_pill">
                    <div className="cart_items_item_top_name-container_pill_left">
                      <div className="cart_items_item_top_name-container_pill_left_name">
                        <FontAwesomeIcon icon={faTruckFast} />
                      </div>
                      <div className="cart_items_item_top_name-container_pill_left_value">
                        {cartTotal > 100 ? "Free Shipping" : "Shipping Cost"}
                      </div>
                    </div>

                    {cartTotal > 100 && (
                      <div className="cart_items_item_top_name-container_pill_right">
                        <div className="cart_items_item_top_name-container_pill_right_name">
                          Disc. Applied
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="cart_items_item_middle">
                <div className="cart_items_item_middle_q">
                  <div
                    onClick={() => deleteItem(idx)}
                    className="cart_items_item_middle_q_delete"
                  >
                    <FontAwesomeIcon icon={faTrashCanList} />
                  </div>
                  <div
                    onClick={() => minusToQuantity(idx)}
                    className="cart_items_item_middle_q_minus"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                  <div className="cart_items_item_middle_q_value">
                    {cart?.quantity}
                  </div>
                  <div
                    onClick={() => addToQuantity(idx)}
                    className="cart_items_item_middle_q_plus"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>

                <div className="cart_items_item_middle_p">
                  <div className="cart_items_item_middle_p_before"> </div>
                  <div className="cart_items_item_middle_p_current">
                    <NumericFormat
                      readOnly
                      value={cart?.item?.price}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

        {checkoutCart?.length === 0 && (
          <div className="cart_items_item_empty">
            <div className="cart_items_item_empty_text">Your cart is empty</div>
          </div>
        )}
      </div>
      {checkoutCart?.length > 0 && (
        <div
          onClick={() => {
            if (items.length > 0) {
              toast.success("Redirecting to checkout page");
              send(`${apiPrefix}/purchase`, {
                items: items,
                allow_promotion_codes: props?.codes?.shipping?.allow_promotion_codes,
                allowed_countries: props?.codes?.shipping?.allowed_countries,
                shippingCost: cartTotal > 100 ? props?.codes?.shipping?.over : props?.codes?.shipping?.cost,
              })
                .then((res) => {
                  if (res?.data?.url) {
                    window.location = res?.data?.url;
                  }
                })
                .catch((err) => {
                 errorHandler(err)
                });
            }
          }}
          className="cart_header_checkout"
        >
          Proceed to checkout
        </div>
      )}
      <div
        onClick={() => {
          showCart$.next(false);
        }}
        className="cart_header_shopping"
      >
        Continue shopping
      </div>
    </div>
  );
};

export default CartComponent;
