import React from "react";
import "./Styles.scss";
import styled, { keyframes, css } from "styled-components";
import NavigationComponent from "../../components/navigation";
import FooterComponent from "../../components/footer";
import BookingFormComponent, {
  artistPageInfo$,
} from "../../components/booking-form";
import * as mobile from "is-mobile";
import { handleRoute } from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import { artistWorkInfo$ } from "../../components/artist-work";

const ArtistAnimation = keyframes`
  0% {
    // transform: scale(1) translateY(400px);
    filter: grayscale(100%);
  }
  100% {
    // transform: scale(1.1) translateY(300px);
    filter: grayscale(0);
  }
`;

const Artist = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1) translateY(500px);
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isanimating &&
    css`
      transform: scale(1.1) translateY(300px);
      animation: ${ArtistAnimation} 2s ease-in-out forwards;

      @media screen and (min-width: 1024px) {
        transform: scale(1.1) translateY(300px) !important;
        animation: ${ArtistAnimation} 2s ease-in-out forwards !important;
      }
    `}
  @media screen and (min-width: 390px) {
    transform: translateY(0);
  }

  @media screen and (min-width: 1024px) {
    transform: scale(1) translateY(500px);
  }
`;

const ArtistContainer = styled.div`
  width: 30%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 2s ease-in-out;
  position: relative;

  ${(props) =>
    props.isanimating &&
    css`
      background-color: ${(props) => props.bk} !important;
    `}

  &:hover {
    width: 100%;
  }

  @media screen and (min-width: 390px) {
    width: 100%;
    background-color: ${(props) => props.bk};
  }

  @media screen and (min-width: 1024px) {
    width: 30%;
    background-color: transparent;
  }
`;

const ArtistName = styled.ul`
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  height: 5vw;
  width: 10%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: 7;

  ${(props) =>
    props.isanimating &&
    css`
      width: 50% !important;

      #q {
        width: 100% !important;
        font-size: 1.6vw;
      }
    `}
`;

const ViewWork = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 2s ease-in-out;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
  bottom: 0;
  font-family: "Varela Round";
  font-size: 1em;
  letter-spacing: 5px;
  color: #88888a;
  text-transform: uppercase;
  line-height: 30px;
  backdrop-filter: blur(200px);
  cursor: pointer;
  opacity: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 33%;
    width: 45px;
    height: 2px;
    background-color: #ffc917;
  }

  &:hover {
    color: white;
  }

  ${(props) =>
    props.viewWork &&
    css`
      width: 90% !important;
      height: 100vh;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 20px;
        right: 35%;
        width: 45px;
        height: 2px;
        background-color: transparent;
      }
    `}

  ${(props) =>
    props.isanimating &&
    css`
      transition: 0.2s ease-in-out;
      opacity: 1 !important;
    `}

    @media screen and (min-width: 390px) {
    width: 50%;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;

    &:after {
      right: 13%;
      width: 25px !important;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    &:after {
      right: 33%;
      width: 45px !important;
    }
  }
`;

const ArtistWork = styled.div`
  width: 20em;
  height: 40em;
  border-radius: 4px;
  border: 2px solid #88888a;
  box-shadow: 0 0 3em -1em #88888a;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 9;
`;

const ArtistBio = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 2s ease-in-out;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
  bottom: 5%;
  font-family: "Varela Round";
  font-size: 1em;
  letter-spacing: 2px;
  color: #88888a;
  text-transform: uppercase;
  line-height: 27px;
  backdrop-filter: blur(200px);
  cursor: pointer;
  opacity: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 33%;
    width: 45px;
    height: 2px;
    background-color: #ffc917;
  }

  &:hover {
    color: white;
  }

  ${(props) =>
    props.viewBio &&
    css`
      height: 90vh;
      width: 90% !important;
      overflow-y: scroll;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 20px;
        right: 35%;
        width: 45px;
        height: 2px;
        background-color: transparent;
      }
    `}

  ${(props) =>
    props.isanimating &&
    css`
      transition: 0.2s ease-in-out;
      opacity: 1 !important;
    `}

    @media screen and (min-width: 390px) {
    bottom: 7%;
    width: 50%;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;

    &:after {
      right: 13%;
      width: 25px !important;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: 5.1%;
    width: 100%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    &:after {
      right: 33%;
      width: 45px !important;
    }
  }
`;

const ArtistBooking = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 2s ease-in-out;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 8%;
  z-index: 8;
  font-family: "Varela Round";
  font-size: 1em;
  letter-spacing: 5px;
  color: #88888a;
  text-transform: uppercase;
  line-height: 30px;
  backdrop-filter: blur(200px);
  cursor: pointer;
  opacity: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 33%;
    width: 45px;
    height: 2px;
    background-color: #ffc917;
  }

  &:hover {
    color: white;
  }

  ${(props) =>
    props.viewBooking &&
    css`
      height: 100vh;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 20px;
        right: 35%;
        width: 45px;
        height: 2px;
        background-color: transparent;
      }
    `}

  ${(props) =>
    props.isanimating &&
    css`
      transition: 0.2s ease-in-out;
      opacity: 1 !important;
    `}

    @media screen and (min-width: 390px) {
    bottom: 15%;
    width: 50%;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;

    &:after {
      right: 13%;
      width: 25px !important;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: 8%;
    width: 100%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    &:after {
      right: 33%;
      width: 45px !important;
    }
  }
`;
const ArtistComponent = ({
  pageData: { artist },
  showAnimation,
  hasAnimation,
  viewWork,
  setViewWork,
  viewBio,
  setViewBio,
  bookArtist,
  setBookArtist,
  handleCancel,
}) => {
  const navigate = useNavigate();
  const isViewing = (item, idx) => {
    console.log('item & id', {item, idx})
    const viewObj = {
      work: () => {
        setViewWork({
          isShowing: true,
          id: idx,
        });
        setViewBio({
          isShowing: false,
          id: -1,
        });
        setBookArtist({
          isShowing: false,
          id: -1,
        });
      },
      bio: () => {
        setViewWork({
          isShowing: false,
          id: -1,
        });
        setViewBio({
          isShowing: true,
          id: idx,
        });
        setBookArtist({
          isShowing: false,
          id: -1,
        });
      },
      artist: () => {
        setViewWork({
          isShowing: false,
          id: -1,
        });
        setViewBio({
          isShowing: false,
          id: -1,
        });
        setBookArtist({
          isShowing: true,
          id: idx,
        });
      },
      default: () => {
        setViewWork({
          isShowing: false,
          id: -1,
        });
        setViewBio({
          isShowing: false,
          id: -1,
        });
        setBookArtist({
          isShowing: false,
          id: -1,
        });
      }
    }
    viewObj[item]();
  }
  return (
    <>
      <div className="artist">
        <NavigationComponent />
        <div className="artist_profiles">
          {artist?.map((item, idx) => (
            <ArtistContainer
              bk={item?.bk}
              id={idx}
              isanimating={showAnimation?.show === idx}
              onMouseEnter={() => hasAnimation(idx)}
              onMouseLeave={() => hasAnimation(-1)}
            >
              {mobile({
                featureDetect: true,
                tablet: true,
              }) && (
                <ArtistName isanimating={showAnimation?.show === idx} id="q">
                  {item?.name?.split("").map((n) => (
                    <li>{n.toUpperCase()}</li>
                  ))}
                </ArtistName>
              )}

              {!mobile({
                featureDetect: true,
                tablet: true,
              }) && (
                <ArtistName
                  isanimating={showAnimation?.show === idx}
                  id="q-mobile"
                >
                  {item?.name?.split("").map((n) => (
                    <li>{n.toUpperCase()}</li>
                  ))}
                </ArtistName>
              )}

              <Artist
                isanimating={showAnimation?.show === idx}
                src={item?.img}
              />
              <ArtistBooking
                onClick={() => isViewing('artist', idx)}
                viewBooking={artist.isShowing === true && idx === artist.id}
                isanimating={showAnimation?.show === idx}
              >
                {bookArtist.id !== idx && <>Book Artist</>}
                {bookArtist.id === idx && !mobile() && (
                  <div className="artist_bio">
                    <BookingFormComponent
                      hasImgUpload={true}
                      handleCancel={handleCancel}
                      title="Book Tattoo Appointment"
                      artist={item?.name}
                      artistImg={item?.img}
                      type="New Tattoo Request"
                    />
                  </div>
                )}
                {bookArtist.id === idx &&
                  mobile({ featureDetect: true, tablet: true }) && (
                    <div className="artist_bio">
                      {artistPageInfo$.next({
                        artist: item?.name,
                        artistImg: item?.img,
                        title: "Book Tattoo Appointment",
                        previous: "/artist",
                      })}
                      {handleRoute("/booking", "locally", navigate)}
                    </div>
                  )}
              </ArtistBooking>
              <ArtistBio
                onClick={() => isViewing('bio', idx)}
                viewBio={viewBio.isShowing === true && idx === viewBio.id}
                isanimating={showAnimation?.show === idx}
              >
                {viewBio.id !== idx && <>View Bio</>}
                {viewBio.id === idx && (
                  <div className="artist__bio">
                    <p>{item?.bio?.top}</p>
                    <p>{item?.bio?.middle}</p>
                    <p>{item?.bio?.bottom}</p>
                  </div>
                )}
              </ArtistBio>
              <ViewWork
                onClick={() => isViewing('work', idx)}
                viewWork={viewWork.isShowing === true && idx === viewWork.id}
                isanimating={showAnimation?.show === idx}
              >
                {viewWork.id !== idx && <>View Work</>}
                {viewWork.id === idx &&
                  mobile({ featureDetect: true, tablet: true }) && (
                    <div className="artist_bio">
                      {artistWorkInfo$.next({
                        tattoos: item?.tattoos,
                        previous: "/artist",
                      })}
                      {handleRoute("/artist-work", "locally", navigate)}
                    </div>
                  )}
                
                {viewWork.id === idx && !mobile({ featureDetect: true, tablet: true }) && (
                  <div className="artist__work">
                    {item?.tattoos?.map((tattoo) => (
                      <ArtistWork img={tattoo?.img} />
                    ))}
                  </div>
                )}
                
              </ViewWork>
            </ArtistContainer>
          ))}
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ArtistComponent;
