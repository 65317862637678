import React, { useEffect, useState } from "react";
import "./styles.scss";
import { toast } from "react-toastify";
import { apiPrefix, send } from "../../shared/api";
import { useForm } from "react-hook-form";
import { BehaviorSubject } from "rxjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { handleRoute } from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import * as mobile from "is-mobile";

const BookerHelper = {
  fileUpload: {},
  hasFile: false,
};

export const artistPageInfo$ = new BehaviorSubject(null);

const BookingFormComponent = (props) => {
  const [fileUpload, setFileUpload] = useState();
  const handleFile$ = new BehaviorSubject("");
  const [artistInfo, setArtistInfo] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    BookerHelper.fileUpload = {};
    BookerHelper.hasFile = false;
    artistPageInfo$.subscribe((artist) => {
      if (artist !== null) {
        setArtistInfo(artist);
      }
    });
  }, []);

  if (watch("imgFile")) {
    BookerHelper.fileUpload = watch("imgFile");
    BookerHelper.hasFile = true;
  }

  const onSubmit = async (data) => {
    let formData = new FormData();

    if (data) {
      formData.append("image", data?.imgFile[0]);
      if (formData) {
        const additionalData = {
          email: data?.email,
          name: data?.name,
          phone: data?.phone,
          description: data?.description,
          type: props?.type || artistInfo?.type,
          requestedArtist: props?.artist ? props?.artist : artistInfo?.artist,
        };
        let sendImage;
        if (data?.imgFile[0])
          sendImage = await toast
            .promise(send(`${apiPrefix}/upload`, formData), {
              error: "Email Image not sent, Try again!! 🤯",
            })
            .catch((e) => {
              console.error("There was an Error sending image", e.message);
            });

        const sendEmail = await toast
          .promise(send(`${apiPrefix}/email`, { ...additionalData }), {
            pending: "Email is on its way 📬",
            success: "Email arrived at Bloodline Dynasty 👌",
            error: "Email not sent, Try again!! 🤯",
          })
          .catch((e) => {
            console.error("There was an Error sending email, e.message");
          });

        Promise.all([sendImage, sendEmail])
          .then((responses) => {
            reset();
          })
          .catch((error) => {
            console.error("Error in sending data:", error);
          });
      }
    }
  };
  const navigate = useNavigate();
  return (
    <div className="booking-form">
      <div class="form-container">
        <div className="form-container_header">
          {mobile({
            featureDetect: true,
            tablet: true,
          }) && (
            <FontAwesomeIcon
              onClick={() =>
                handleRoute(artistInfo?.previous, "locally", navigate)
              }
              className="artist_x"
              icon={faCircleChevronLeft}
            />
          )}
          <div className="form_container_header_title">
            <h2>{props?.title || artistInfo?.title}</h2>
            {props?.artist ||
              (artistInfo?.artist && (
                <h4>Artist: {props?.artist || artistInfo?.artist}</h4>
              ))}
          </div>
          <div className="form_container_header_artist-img">
            <img src={props?.artistImg || artistInfo?.artistImg} alt="" />
          </div>
        </div>
        <form class="form">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              {...register("name")}
              type="text"
              id="name"
              name="name"
              required=""
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              {...register("phone")}
              type="text"
              id="phone"
              name="phone"
              required=""
            />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input
              {...register("email")}
              type="text"
              id="email"
              name="email"
              required=""
            />
          </div>
          <div class="form-group">
            <label for="textarea">Description</label>
            <textarea
              name="textarea"
              id="textarea"
              rows="10"
              cols="50"
              required=""
              {...register("description")}
            >
              {" "}
            </textarea>
          </div>
          {props.hasImgUpload && (
            <div className="form-group">
              <form class="modal" enctype="multipart/form-data">
                <div class="content">
                  <span class="title">Upload an Image</span>
                  <p class="message">
                    Select an image to upload from your computer or device.
                  </p>

                  <div class="actions">
                    <label for="file" class="button upload-btn">
                      Choose File
                      <input
                        {...register("imgFile")}
                        hidden=""
                        type="file"
                        id="file"
                        accept="image/*"
                      />
                    </label>
                  </div>
                  {BookerHelper.hasFile && (
                    <div class="result">
                      <div class="file-uploaded">
                        <p>{BookerHelper.fileUpload[0]?.name}</p>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
          <div
            onClick={handleSubmit(onSubmit)}
            class="form-submit-btn"
            type="submit"
          >
            Submit
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingFormComponent;
