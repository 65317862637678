import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import {
  addCart$,
  appID,
  breadCrumbs$,
  handleCart$,
  playVideo$,
  showCart$,
} from "../../shared/app-service";
import { apiPrefix } from "../../shared/api";

/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

export const shopHelper = {
  isCartOpen: false,
};

export const useShopHook = () => {
  const [pageData, setPageData] = useState({});
  const [itemSelected, setItemSelected] = useState("shirts");
  const [itemHovered, setItemHovered] = useState("shirts");
  const [apparelType, setApparelType] = useState("");
  const [aftercareType, setAftercareType] = useState("");
  const [smokeType, setSmokeType] = useState("");
  const [jewelryType, setJewelryType] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [cartShowing, setCartShowing] = useState(false);
  const cart = JSON.parse(localStorage.getItem(`${appID}cart`)) || [];
  const videoEl = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const attemptPlay = useCallback(() => {
    playVideo$.next('')
    if (videoEl.current) {
      setIsVideoPlaying(true);
      videoEl?.current
        ?.play()
        .catch((error) => {
          setIsVideoPlaying(false);
          console.error("Error attempting to play", error);
        });
    } else {
      setIsVideoPlaying(false);
    }
  }, [videoEl]);

  const addApparelToCart = (item) => {
    let found = cart.find(
      (i) => i?.id === item?.available?.find((i) => i?.id === item?.id).apiId
    );
    if (found) {
      found.quantity = 1;
      localStorage.setItem(`${appID}cart`, JSON.stringify([...cart]));
      handleCart$.next(cart);
      addCart$.next(cart);
      return cart;
    } else {
      cart.push({
        id: item?.available?.find((i) => i?.id === item?.id).apiId,
        quantity: item.quantity,
        item: item,
        totalPrice: item?.originalPrice,
      });
      localStorage.setItem(`${appID}cart`, JSON.stringify(cart));
      handleCart$.next(cart);
      addCart$.next(cart);
      return cart;
    }
  };

  const addToCart = (item) => {
    console.log("jewel", item);
    let found = cart.find((i) => i.id === item.apiId);
    if (found) {
      found.quantity = 1;
      localStorage.setItem(`${appID}cart`, JSON.stringify([...cart]));
      handleCart$.next(cart);
      addCart$.next(cart);
      return cart;
    } else {
      cart.push({
        id: item.apiId,
        quantity: item.quantity,
        item: item,
        totalPrice: item?.originalPrice,
      });
      localStorage.setItem(`${appID}cart`, JSON.stringify(cart));
      handleCart$.next(cart);
      addCart$.next(cart);
      return cart;
    }
  };

  useEffect(() => {
    hasCookieAndStorage("store")
      ? setPageData(decode(appStore.get(`${appID}store`)))
      : setSessionData(appID, "store", `${apiPrefix}/store`, setPageData);
  }, []);

  useEffect(() => {
    let crumbs = [
      {
        name: "Home",
        link: "/main",
        hasCrumb: true,
      },
      {
        name: "Store",
        link: "/store",
        hasCrumb: false,
      },
    ];
    breadCrumbs$.next(crumbs);

    showCart$.subscribe((cart) => {
      console.log("cart", cart);
      shopHelper.isCartOpen = cart;
      setCartShowing(cart);
    });

    handleCart$.subscribe((cart) => {
      cart = cart;
    });
  }, [breadCrumbs$, showCart$]);

  useEffect(() => {
    attemptPlay();
  }, [attemptPlay, isVideoPlaying]);

  useEffect(() => {
    playVideo$.subscribe((video) => {
      console.log('vvv  ', video);
      setIsVideoPlaying(true)
    });
  }, []);

  // other component logic or functions

  return {
    // This return object is what gets passed as props to the componen
    pageData,
    itemSelected,
    setItemSelected,
    itemHovered,
    setItemHovered,
    apparelType,
    setApparelType,
    aftercareType,
    setAftercareType,
    smokeType,
    setSmokeType,
    jewelryType,
    setJewelryType,
    selectedSize,
    setSelectedSize,
    cartShowing,
    cart,
    addApparelToCart,
    addToCart,
    videoEl,
    isVideoPlaying,
    setIsVideoPlaying,
  };
};
