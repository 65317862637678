import React, { useEffect, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";
import { BehaviorSubject } from "rxjs";
import { apiPrefix } from "../../shared/api";

/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

const notifyCancel$ = new BehaviorSubject(null)

export const useArtistHook = () => {
  const [showAnimation, setAnimation] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [viewBio, setViewBio] = useState(false);
  const [bookArtist, setBookArtist] = useState(false);
  const [pageData, setPageData] = useState({});

  const hasAnimation = (item) => {
    setAnimation({ show: item });
  };

  const handleCancel = (idx) => {
    setBookArtist(idx);
  }

  useEffect(() => {
    hasCookieAndStorage("artist")
      ? setPageData(decode(appStore.get(`${appID}artist`)))
      : setSessionData(appID, "artist", `${apiPrefix}/artist`, setPageData);
  }, []);



  // other component logic or functions

  return {
    pageData,
    showAnimation,
    setAnimation,
    hasAnimation,
    viewWork,
    setViewWork,
    viewBio,
    setViewBio,
    bookArtist,
    setBookArtist,
    handleCancel
  };
};
