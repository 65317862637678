// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #000;
}

#canvas {
  display: block;
  width: 100%;
  height: 100%;

  body.o-start & {
    cursor: none;
  }
}

#message {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: rgba(#fff, 0.7);
  font-family: Georgia, serif;
  font-size: 0.9rem;
  text-align: center;
  letter-spacing: 0.1em;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms;

  body.o-start & {
    opacity: 1;
  }
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;;EAEE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;;EAEZ;IACE,YAAY;EACd;AACF;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,OAAO;EACP,sBAAsB;EACtB,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,UAAU;EACV,yBAAyB;;EAEzB;IACE,UAAU;EACZ;AACF","sourcesContent":["\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  background-color: #000;\n}\n\n#canvas {\n  display: block;\n  width: 100%;\n  height: 100%;\n\n  body.o-start & {\n    cursor: none;\n  }\n}\n\n#message {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  color: rgba(#fff, 0.7);\n  font-family: Georgia, serif;\n  font-size: 0.9rem;\n  text-align: center;\n  letter-spacing: 0.1em;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity 500ms;\n\n  body.o-start & {\n    opacity: 1;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
